import * as SentryBrowser from '@sentry/browser';
import * as SentryVue from '@sentry/vue';

function validateConfig() {
    return (
        typeof window.WPY_SENTRY !== 'undefined' &&
        typeof window.WPY_SENTRY.RELEASE !== 'undefined' &&
        typeof window.WPY_SENTRY.DSN !== 'undefined'
    );
}

function addAllData(SentryObject) {
    const timestamp = new Date().toLocaleString('en-US', { timeZone: 'America/Toronto', timeZoneName: 'short' });

    SentryObject.setUser(window.WPY_SENTRY.USER);
    SentryObject.setExtra('sentry_init_timestamp', timestamp);
}

export function init() {
    if (!validateConfig()) {
        return false;
    }

    SentryBrowser.init(getOptions([SentryBrowser.browserTracingIntegration()]));
    addAllData(SentryBrowser);

    window.WPY_SENTRY.Sentry = SentryBrowser;
}

export function initVue(Vue, settings) {
    if (!validateConfig()) {
        return false;
    }

    settings = settings || {};

    let options = getOptions([
        SentryVue.browserTracingIntegration({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /poweredbymagnet\./],
        }),

        SentryVue.replayIntegration({
            maskAllText: true,
        }),
    ]);

    options = { Vue, ...options };

    SentryVue.init(options);
    addAllData(SentryVue);
}

export function getOptions(integrations) {
    return {
        release: window.WPY_SENTRY.RELEASE,
        dsn: window.WPY_SENTRY.DSN,
        integrations: integrations,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: window.WPY_SENTRY.TRACES_SAMPLE_RATE || 0.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        // https://docs.sentry.io/platforms/javascript/guides/svelte/session-replay/#sampling
        replaysSessionSampleRate: window.WPY_SENTRY.REPLAYS_SESSION_SAMPLE_RATE || 0.0,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        // It's recommended to set to 1.0 even in production
        // https://docs.sentry.io/platforms/javascript/guides/svelte/session-replay/#sampling
        replaysOnErrorSampleRate: window.WPY_SENTRY.REPLAYS_ON_ERROR_SAMPLE_RATE || 1.0,

        // Set the environment to match the laravel environment
        environment: window.WPY_SENTRY.ENVIRONMENT || 'unknown',

        beforeSendTransaction(event) {
            return event;
        },

        beforeSend(event, hint) {
            return event;
        },

        /**
         * A pattern for error messages which should not be sent to Sentry.
         *
         * https://docs.sentry.io/clients/javascript/tips/
         * Without the added ignore options, you'll quickly find yourself swamped with un-actionable exceptions
         * due to shoddy browser plugins and 3rd party script errors.
         */
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            // Generic error code from errors outside the security sandbox
            // You can delete this if using raven.js > 1.0, which ignores these automatically.
            'Script error.',
            // Avast extension error
            '_avast_submit',
            /**
             * The following are errors we have specifically identified
             */
            // When multiple extensions are installed that both use an older version of sideex-api
            // this error is thrown.
            // https://github.com/SideeX/sideex-api/blob/master/src/page/prompt.js
            "Identifier 'originalPrompt' has already been declared",
            // Email link Microsoft Outlook crawler compatibility error
            // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
            'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
            // Google Recaptcha causes error
            // https://github.com/getsentry/sentry-javascript/issues/2514
            'Non-Error promise rejection captured with value: TimeoutError',
            // Safari throws this error when trying to access the `@context` property of JSON-LD
            'TypeError: undefined is not an object (evaluating \'r["@context"].toLowerCase\')',

            // AxiosError's happen on 401s, 403s, etc. and are not really errors
            // We see messages like:
            // "Request failed with status code <code>"
            // "Network Error"
            // They usually happen if the user had a bad connection to our back-end correctly returned an error response.
            /^AxiosError/,
        ],
        /**
         * A pattern for error URLs which should not be sent to Sentry.
         * To allow certain errors instead, use {@link Options.allowUrls}.
         * By default, all errors will be sent.
         */
        denyUrls: [
            // Google Adsense
            /pagead\/js/i,
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
    };
}
